"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const InfoCliente_1 = require("@/components/context/InfoCliente");
const react_1 = __importStar(require("react"));
const InfoCliente = () => {
    const { loja } = (0, react_1.useContext)(InfoCliente_1.InfoClienteContext);
    return (react_1.default.createElement("div", { className: "orcamento", style: { paddingBottom: '3rem' } }, loja.nome_fantasia !== 'Escolher Loja' ?
        react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("span", { className: "titulo" },
                react_1.default.createElement("label", { className: "fa fa-info-circle" }),
                "Informa\u00E7\u00F5es do cliente"),
            react_1.default.createElement("div", { className: "detalhes-pedido w-100" },
                react_1.default.createElement("div", null,
                    react_1.default.createElement("ul", null,
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Raz\u00E3o social:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.razao_social)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Nome fantasia:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.nome_fantasia)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "CNPJ:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.cnpj)))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("ul", null,
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Estado:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.estado)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Municipio:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.municipio)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Bairro:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.bairro)))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("ul", null,
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "CEP:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.cep)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "Ender\u00E7o:"),
                            react_1.default.createElement("span", { className: "dados" }, loja.endereco)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", null, "C\u00F3digo - Loja:"),
                            react_1.default.createElement("span", { className: "dados" },
                                loja.codigo,
                                " - ",
                                loja.loja))))))
        :
            react_1.default.createElement("span", { className: "titulo", style: { color: '#ff0000' } },
                react_1.default.createElement("label", { className: "fa fa-times-circle" }),
                "Selecione uma loja")));
};
exports.default = InfoCliente;
