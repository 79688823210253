"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryString = void 0;
const react_router_dom_1 = require("react-router-dom");
const getQueryString = (param) => {
    if (STORYBOOK)
        return param;
    return new URLSearchParams((0, react_router_dom_1.useLocation)().search).get(param);
};
exports.getQueryString = getQueryString;
exports.default = {};
