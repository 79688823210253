"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generalSettingsNormalizer = exports.userNormalizer = exports.estoqueSaldoNormalizer = exports.titulosReceberNormalizer = void 0;
const normalizr_1 = require("normalizr");
const models_1 = require("@/models");
//inicio
const userSchema = new normalizr_1.schema.Entity('user', {}, {
    processStrategy: user => new models_1.User(user),
});
const userNormalizer = (user) => (0, normalizr_1.normalize)(user, userSchema);
exports.userNormalizer = userNormalizer;
//fim
//inicio
const generalSettingsSchema = new normalizr_1.schema.Entity('generalSettings', {}, {
    processStrategy: generalSettings => new models_1.GeneralSettings(generalSettings),
});
const generalSettingsNormalizer = (generalSettings) => (0, normalizr_1.normalize)(generalSettings, generalSettingsSchema);
exports.generalSettingsNormalizer = generalSettingsNormalizer;
//fim
//inicio
const estoqueSaldoSchema = new normalizr_1.schema.Entity('estoqueSaldo', {}, {
    processStrategy: estoqueSaldo => new models_1.EstoqueSaldo(estoqueSaldo),
});
const estoqueSaldoNormalizer = (estoqueSaldo) => (0, normalizr_1.normalize)(estoqueSaldo, estoqueSaldoSchema);
exports.estoqueSaldoNormalizer = estoqueSaldoNormalizer;
//fim
//inicio
const titulosReceberSchema = new normalizr_1.schema.Entity('titulosReceber', {}, {
    processStrategy: titulosReceber => new models_1.TitulosReceber(titulosReceber),
});
const titulosReceberNormalizer = (titulosReceber) => (0, normalizr_1.normalize)(titulosReceber, titulosReceberSchema);
exports.titulosReceberNormalizer = titulosReceberNormalizer;
