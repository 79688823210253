// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,body{min-height:100%;background:#2d353c}.app-body{min-height:100%;background:#2d353c}\n", "",{"version":3,"sources":["webpack://styles/defaults.scss"],"names":[],"mappings":"AAAoL,UAAU,eAAe,CAAC,kBAAkB,CAAC,UAAU,eAAe,CAAC,kBAAkB","sourcesContent":[":export{error:#dc3545;primary:#000;standardTextColor:#212529}:export{borderRadius:5px;buttonHeight:39px;buttonLargeWidth:200px;buttonSmallWidth:100px;buttonWidth:150px;spacer:15px}html,body{min-height:100%;background:#2d353c}.app-body{min-height:100%;background:#2d353c}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "#dc3545",
	"primary": "#000",
	"standardTextColor": "#212529",
	"borderRadius": "5px",
	"buttonHeight": "39px",
	"buttonLargeWidth": "200px",
	"buttonSmallWidth": "100px",
	"buttonWidth": "150px",
	"spacer": "15px"
};
export default ___CSS_LOADER_EXPORT___;
