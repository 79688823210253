"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelContextProvider = exports.PanelContext = void 0;
const react_1 = __importStar(require("react"));
const initialValue = {
    setExpand: () => { },
    setRemove: () => { },
    setCollapse: () => { },
    setReload: () => { },
    expand: false,
    remove: false,
    collapse: false,
    reload: false,
    toggleReload: () => { }
};
exports.PanelContext = (0, react_1.createContext)(initialValue);
const PanelContextProvider = ({ children }) => {
    const [expand, setExpand] = (0, react_1.useState)(false);
    const [remove, setRemove] = (0, react_1.useState)(false);
    const [collapse, setCollapse] = (0, react_1.useState)(false);
    const [reload, setReload] = (0, react_1.useState)(false);
    function toggleReload() {
        if (reload !== true) {
            setReload(true);
        }
        setTimeout(() => {
            setReload(false);
        }, 2000);
    }
    return (react_1.default.createElement(exports.PanelContext.Provider, { value: {
            expand,
            setExpand,
            remove,
            setRemove,
            collapse,
            setCollapse,
            reload,
            setReload,
            toggleReload
        } }, children));
};
exports.PanelContextProvider = PanelContextProvider;
