"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Api = exports.setJWTTokens = void 0;
const api_1 = require("@/redux/api");
let jwtTokens;
const setJWTTokens = (tokens) => {
    jwtTokens = tokens;
};
exports.setJWTTokens = setJWTTokens;
exports.Api = {
    get: (parameters) => (0, api_1.callApi)({ ...parameters, jwtTokens, method: 'GET' }),
    post: (parameters) => (0, api_1.callApi)({ ...parameters, jwtTokens, method: 'POST' }),
};
