// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonWrapper{display:flex;flex-direction:row;justify-content:space-between;padding:15px}@media screen and (max-width: 768px){.buttonWrapper{align-items:center;flex-direction:column-reverse;gap:15px}.buttonWrapper button{width:100% !important}}\n", "",{"version":3,"sources":["webpack://src/components/buttons-wrapper/styles.scss"],"names":[],"mappings":"AAAoL,eAAe,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,YAAY,CAAC,qCAAqC,eAAe,kBAAkB,CAAC,6BAA6B,CAAC,QAAQ,CAAC,sBAAsB,qBAAqB,CAAC","sourcesContent":[":export{error:#dc3545;primary:#000;standardTextColor:#212529}:export{borderRadius:5px;buttonHeight:39px;buttonLargeWidth:200px;buttonSmallWidth:100px;buttonWidth:150px;spacer:15px}.buttonWrapper{display:flex;flex-direction:row;justify-content:space-between;padding:15px}@media screen and (max-width: 768px){.buttonWrapper{align-items:center;flex-direction:column-reverse;gap:15px}.buttonWrapper button{width:100% !important}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "#dc3545",
	"primary": "#000",
	"standardTextColor": "#212529",
	"borderRadius": "5px",
	"buttonHeight": "39px",
	"buttonLargeWidth": "200px",
	"buttonSmallWidth": "100px",
	"buttonWidth": "150px",
	"spacer": "15px"
};
export default ___CSS_LOADER_EXPORT___;
