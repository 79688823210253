"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextProvider = exports.Context = void 0;
const react_1 = __importStar(require("react"));
const initialValue = {
    setMenuMarket: () => { },
    menuMarket: false,
    toggleAppSidebarMobile: () => { },
    toggleAppSidebarMinify: () => { },
    setActive: () => { },
    setClicked: () => { },
    appSidebarMobileToggled: false,
    appSidebarMinify: false,
    active: -1,
    clicked: -1,
    closeSidebar: () => { },
    handleExpand: () => { },
    setMatch: () => { },
    Match: false
};
exports.Context = (0, react_1.createContext)(initialValue);
const ContextProvider = ({ children }) => {
    const [menuMarket, setMenuMarket] = (0, react_1.useState)(initialValue.menuMarket);
    const [appSidebarMobileToggled, toggleAppSidebarMobile] = (0, react_1.useState)(initialValue.appSidebarMobileToggled);
    const [appSidebarMinify, toggleAppSidebarMinify] = (0, react_1.useState)(initialValue.appSidebarMinify);
    const [active, setActive] = (0, react_1.useState)(initialValue.active);
    const [clicked, setClicked] = (0, react_1.useState)(initialValue.clicked);
    const [Match, setMatch] = (0, react_1.useState)(initialValue.Match);
    function closeSidebar() {
        toggleAppSidebarMinify(false);
        console.log('teste');
        toggleAppSidebarMobile(!appSidebarMobileToggled);
    }
    function handleExpand(e, i, match) {
        e.preventDefault();
        if (clicked === -1 && match) {
            setActive(-1);
            setClicked(1);
            setMatch(!Match);
        }
        else {
            setActive(active === i ? -1 : i);
            setClicked(1);
            setMatch(!Match);
        }
    }
    return (react_1.default.createElement(exports.Context.Provider, { value: {
            menuMarket,
            setMenuMarket,
            toggleAppSidebarMobile,
            appSidebarMobileToggled,
            toggleAppSidebarMinify,
            appSidebarMinify,
            closeSidebar,
            active,
            setActive,
            clicked,
            setClicked,
            handleExpand,
            setMatch,
            Match
        } }, children));
};
exports.ContextProvider = ContextProvider;
