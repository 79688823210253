"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const redux_1 = require("@/redux");
const common_1 = require("@/redux/common");
const user_1 = require("@/redux/user");
const user = __importStar(require("./user"));
function* root() {
    yield (0, effects_1.spawn)(function* run() {
        yield (0, effects_1.all)([
            (0, effects_1.takeLatest)((0, common_1.getSuccessActionName)(redux_1.ActionCreators.user.login.actionName), user.setUserSession),
            (0, effects_1.takeLatest)((0, common_1.getSuccessActionName)(redux_1.ActionCreators.user.logout.actionName), user.logout),
            (0, effects_1.takeLatest)(user_1.ACTIONS.TOKEN_REFRESH, user.setAuthToken),
            (0, effects_1.takeLatest)((0, common_1.getSuccessActionName)(redux_1.ActionCreators.user.updateUser.actionName), user.setUserSession),
            (0, effects_1.takeLatest)((0, common_1.getSuccessActionName)(redux_1.ActionCreators.user.resetPasswordRequest.actionName), user.resetPasswordRequest),
            (0, effects_1.takeLatest)((0, common_1.getSuccessActionName)(redux_1.ActionCreators.user.resetPasswordChange.actionName), user.resetPasswordChange),
        ]);
    });
    yield (0, effects_1.spawn)(user.startup);
}
exports.default = root;
