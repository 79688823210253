"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const Context_1 = require("../context/Context");
function SidebarNavList(props) {
    const { clicked, appSidebarMinify } = (0, react_1.useContext)(Context_1.Context);
    var icon = props.data.icon && react_1.default.createElement("div", { className: "menu-icon" },
        react_1.default.createElement("i", { className: props.data.icon }));
    var img = props.data.img && react_1.default.createElement("div", { className: "menu-icon-img" },
        react_1.default.createElement("img", { src: props.data.img, alt: "" }));
    var caret = (props.data.children && !props.data.badge) && react_1.default.createElement("div", { className: "menu-caret fa fa-chevron-right" });
    var label = props.data.label && react_1.default.createElement("span", { className: "menu-label ms-5px color:white" }, props.data.label);
    var badge = props.data.badge && react_1.default.createElement("div", { className: "menu-badge" }, props.data.badge);
    var highlight = props.data.highlight && react_1.default.createElement("i", { className: "fa fa-paper-plane text-theme" });
    var title = props.data.title && react_1.default.createElement("div", { className: "menu-text" },
        props.data.title,
        " ",
        label,
        " ",
        highlight);
    const [subMenu, setSubMenu] = (0, react_1.useState)(-1);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_router_dom_1.Route, { path: props.data.path, exact: props.data.exact, children: ({ match }) => (react_1.default.createElement("div", { className: "menu-item " + (match ? "active " : "") + ((props.active || (props.clicked === -1 && match) || props.data.search) ? 'expand ' : 'closed ') + (props.data.children ? "has-sub " : "") },
                props.data.children ? (react_1.default.createElement(react_router_dom_1.Link, { to: props.data.path, className: "menu-link", onClick: props.expand },
                    img,
                    " ",
                    icon,
                    " ",
                    title,
                    caret,
                    " ",
                    badge)) : (react_1.default.createElement(react_router_dom_1.Link, { to: props.data.path, className: "menu-link" },
                    img,
                    " ",
                    icon,
                    " ",
                    badge,
                    " ",
                    title,
                    caret)),
                props.data.children && (react_1.default.createElement("div", { className: "menu-submenu " + (((props.active || (props.clicked === -1 && match) || props.data.search) && !appSidebarMinify) ? 'd-block ' : 'd-none') }, props.data.children && props.data.children.map((submenu, i) => {
                    return (react_1.default.createElement(react_router_dom_1.Route, { path: submenu.path, exact: submenu.exact, key: i, children: ({ match }) => (react_1.default.createElement(SidebarNavList, { data: submenu, key: i, expand: () => subMenu === i ? setSubMenu(-1) : setSubMenu(i), active: subMenu === i, clicked: clicked })) }));
                }))))) })));
}
exports.default = SidebarNavList;
