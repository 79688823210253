"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const formik_1 = require("formik");
const fields_1 = require("../base/fields");
const components_1 = require("@/components");
const schemas_1 = require("../schemas");
const CadastroContext_1 = require("@/context/CadastroContext");
const PutDelFilialForm = (props) => {
    const rowOriginal = props;
    const initialValue = {
        [schemas_1.PutDelFilialFieldNames.id]: rowOriginal.id,
        [schemas_1.PutDelFilialFieldNames.cnpj]: rowOriginal.cnpj,
        [schemas_1.PutDelFilialFieldNames.razao_social]: rowOriginal.razao_social,
        [schemas_1.PutDelFilialFieldNames.nome_fantasia]: rowOriginal.nome_fantasia,
    };
    const { setPutFilial, setFilialId } = (0, react_1.useContext)(CadastroContext_1.CadastroContext);
    const [deletar, setDeletar] = (0, react_1.useState)(false);
    const [alterar, setAlterar] = (0, react_1.useState)(false);
    const Delete = () => {
        setDeletar(true);
        setAlterar(false);
    };
    const Put = () => {
        setDeletar(false);
        setAlterar(true);
    };
    return (react_1.default.createElement(formik_1.Formik, { initialValues: initialValue, onSubmit: (values) => {
            if (alterar && !deletar) {
                setPutFilial(values);
                setAlterar(false);
            }
            else if (deletar && !alterar) {
                setFilialId(values.id);
                setDeletar(false);
            }
        }, validationSchema: schemas_1.PutDelFilialSchema, validateOnChange: true }, ({ isValid, dirty }) => (react_1.default.createElement(formik_1.Form, { noValidate: true },
        react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "form-floating mb-15px" },
                react_1.default.createElement(fields_1.TextField, { label: "Raz\u00E3o Social", name: schemas_1.PutDelFilialFieldNames.razao_social, placeholder: '' })),
            react_1.default.createElement("div", { className: "form-floating mb-15px" },
                react_1.default.createElement(fields_1.TextField, { label: "Nome Fantasia", name: schemas_1.PutDelFilialFieldNames.nome_fantasia, placeholder: '' }))),
        react_1.default.createElement("div", { className: "row" },
            react_1.default.createElement("div", { className: "col-xl-6 p-5px" },
                react_1.default.createElement(components_1.Button, { title: 'Alterar', onClick: () => Put(), type: "submit", className: "btn btn-success d-block h-45px w-100 btn-lg fs-14px", fakeDisabled: !isValid || !dirty })),
            react_1.default.createElement("div", { className: "col-xl-6 p-5px" },
                react_1.default.createElement(components_1.Button, { title: 'Deletar', onClick: () => Delete(), type: "submit", className: "btn btn-danger d-block h-45px w-100 btn-lg fs-14px", fakeDisabled: !isValid || !dirty })))))));
};
exports.default = PutDelFilialForm;
