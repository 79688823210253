"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteLocalStorage = exports.setLocalStorage = exports.getLocalStorage = void 0;
const getLocalStorage = (name) => localStorage.getItem(name);
exports.getLocalStorage = getLocalStorage;
const setLocalStorage = (name, data) => {
    localStorage.setItem(name, data);
};
exports.setLocalStorage = setLocalStorage;
const deleteLocalStorage = (name) => {
    localStorage.setItem(name, '');
};
exports.deleteLocalStorage = deleteLocalStorage;
exports.default = {};
