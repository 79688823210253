"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CadastroContextProvider = exports.CadastroContext = void 0;
const react_1 = __importStar(require("react"));
const initialValue = {
    ECNome: {},
    setECNome: () => { },
    ECId: -1,
    setECId: () => { },
    putEC: {},
    setPutEC: () => { },
    filialNome: {},
    setFilialNome: () => { },
    filialId: -1,
    setFilialId: () => { },
    putFilial: {},
    setPutFilial: () => { },
    adminNome: {},
    setAdminNome: () => { },
    adminId: -1,
    setAdminId: () => { },
    putAdmin: {},
    setPutAdmin: () => { },
    bandeiraNome: {},
    setBandeiraNome: () => { },
    bandeiraId: -1,
    setBandeiraId: () => { },
    putBandeira: {},
    setPutBandeira: () => { },
    setPutDeleteValues: () => { },
    putDeleteValues: {},
    setValues: () => { },
    values: {},
    setId: () => { },
    id: -1,
};
exports.CadastroContext = (0, react_1.createContext)(initialValue);
const CadastroContextProvider = ({ children }) => {
    const [values, setValues] = (0, react_1.useState)(initialValue.values);
    const [putDeleteValues, setPutDeleteValues] = (0, react_1.useState)(initialValue.putDeleteValues);
    const [ECNome, setECNome] = (0, react_1.useState)(initialValue.ECNome);
    const [ECId, setECId] = (0, react_1.useState)(initialValue.ECId);
    const [putEC, setPutEC] = (0, react_1.useState)(initialValue.putEC);
    const [filialNome, setFilialNome] = (0, react_1.useState)(initialValue.filialNome);
    const [filialId, setFilialId] = (0, react_1.useState)(initialValue.filialId);
    const [putFilial, setPutFilial] = (0, react_1.useState)(initialValue.putFilial);
    const [adminNome, setAdminNome] = (0, react_1.useState)(initialValue.adminNome);
    const [adminId, setAdminId] = (0, react_1.useState)(initialValue.adminId);
    const [putAdmin, setPutAdmin] = (0, react_1.useState)(initialValue.putAdmin);
    const [bandeiraNome, setBandeiraNome] = (0, react_1.useState)(initialValue.bandeiraNome);
    const [bandeiraId, setBandeiraId] = (0, react_1.useState)(initialValue.bandeiraId);
    const [putBandeira, setPutBandeira] = (0, react_1.useState)(initialValue.putBandeira);
    const [id, setId] = (0, react_1.useState)(initialValue.id);
    return (react_1.default.createElement(exports.CadastroContext.Provider, { value: {
            ECNome, setECNome,
            ECId, setECId,
            putEC, setPutEC,
            filialNome, setFilialNome,
            filialId, setFilialId,
            putFilial, setPutFilial,
            adminNome, setAdminNome,
            adminId, setAdminId,
            putAdmin, setPutAdmin,
            bandeiraNome, setBandeiraNome,
            bandeiraId, setBandeiraId,
            putBandeira, setPutBandeira,
            putDeleteValues, setPutDeleteValues,
            values, setValues,
            id, setId,
        } }, children));
};
exports.CadastroContextProvider = CadastroContextProvider;
