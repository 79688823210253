"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Menu = [
    { path: '/home', icon: 'fa fa-th-large', title: 'Home' },
    { path: '/banco-de-imagem', icon: 'fa fa-image', title: 'Banco de Imagem' },
    { path: '/estoque', icon: 'fa fa-cubes', title: 'Estoque' },
    { path: '/financeiro', icon: 'fa fa-dollar-sign', title: 'Financeiro' },
    /* { path: '/market', icon: 'fa fa-store', title: 'Loja'}, */
];
exports.default = Menu;
