"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerCreator = exports.getFailureActionName = exports.getSuccessActionName = void 0;
const getSuccessActionName = (actionName) => `${actionName}_SUCCESS`;
exports.getSuccessActionName = getSuccessActionName;
const getFailureActionName = (actionName) => `${actionName}_FAILURE`;
exports.getFailureActionName = getFailureActionName;
const reducerCreator = (state, action, actionCreators) => {
    let newState = { ...state };
    Object.entries(actionCreators).forEach(([key]) => {
        const actionCreator = actionCreators[key];
        if (actionCreator.onActionSuccess &&
            (0, exports.getSuccessActionName)(actionCreator.actionName) === action.type) {
            newState = actionCreator.onActionSuccess(state, action);
        }
        if (actionCreator.onActionFailed &&
            (0, exports.getFailureActionName)(actionCreator.actionName) === action.type) {
            newState = actionCreator.onActionFailed(state, action);
        }
    });
    return newState;
};
exports.reducerCreator = reducerCreator;
