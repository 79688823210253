"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variant = exports.Size = void 0;
var Size;
(function (Size) {
    Size["large"] = "btn-lg";
    Size["small"] = "btn-sm";
})(Size = exports.Size || (exports.Size = {}));
var variant;
(function (variant) {
    variant["primary"] = "btn-primary";
    variant["secondary"] = "btn-secondary";
    variant["success"] = "btn-success";
    variant["danger"] = "btn-danger";
    variant["warning"] = "btn-warning";
    variant["info"] = "btn-info";
    variant["light"] = "btn-light";
    variant["dark"] = "btn-dark";
})(variant = exports.variant || (exports.variant = {}));
