"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAuth = exports.getAuth = exports.setAuth = void 0;
const constants_1 = require("@/constants");
const local_storage_1 = require("./local-storage");
const setAuth = (data) => (0, local_storage_1.setLocalStorage)(constants_1.LocalStorageKeys.auth, JSON.stringify(data));
exports.setAuth = setAuth;
const getAuth = () => {
    const token = (0, local_storage_1.getLocalStorage)(constants_1.LocalStorageKeys.auth);
    if (token) {
        return JSON.parse(token);
    }
    return null;
};
exports.getAuth = getAuth;
const deleteAuth = () => (0, local_storage_1.deleteLocalStorage)(constants_1.LocalStorageKeys.auth);
exports.deleteAuth = deleteAuth;
exports.default = {};
