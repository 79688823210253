// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*@import \"https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700\";*/", "",{"version":3,"sources":["webpack://index.css"],"names":[],"mappings":"AAAA,+EAA+E","sourcesContent":["/*@import \"https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700\";*/\n\n@import \"https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css\";\n@import \"https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css\";\n\n@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
