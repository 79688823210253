"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelFooter = exports.PanelBody = exports.PanelHeader = exports.Panel = void 0;
const react_1 = __importStar(require("react"));
const PanelContext_1 = require("../context/PanelContext");
function Panel(props) {
    const { expand, remove, reload } = (0, react_1.useContext)(PanelContext_1.PanelContext);
    return (react_1.default.createElement("div", null, (!remove &&
        react_1.default.createElement("div", { className: 'panel panel-inverse' + ' ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ') + (props.className ? props.className : '') }, props.children))));
}
exports.Panel = Panel;
function PanelHeader(props) {
    const { expand, setExpand, remove, setRemove, collapse, setCollapse, toggleReload } = (0, react_1.useContext)(PanelContext_1.PanelContext);
    return (react_1.default.createElement("div", { className: 'panel-heading ' + props.className },
        react_1.default.createElement("h4", { className: "panel-title" }, props.children),
        (!props.noButton &&
            react_1.default.createElement("div", { className: "panel-heading-btn" }))));
}
exports.PanelHeader = PanelHeader;
function PanelBody(props) {
    const { collapse, reload } = (0, react_1.useContext)(PanelContext_1.PanelContext);
    return (react_1.default.createElement("div", { className: "panel-body " + (collapse ? 'd-none ' : ' ') + props.className },
        props.children,
        (reload &&
            react_1.default.createElement("div", { className: "panel-loader" },
                react_1.default.createElement("span", { className: "spinner spinner-sm" })))));
}
exports.PanelBody = PanelBody;
function PanelFooter(props) {
    return (react_1.default.createElement("div", { className: "panel-footer " + props.className }, props.children));
}
exports.PanelFooter = PanelFooter;
